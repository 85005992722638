/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
                $("#owl-landing").owlCarousel({
                    navigationText: ['<i class="prev"><span class="icon-wrap"></span></i>', '<i class="next"><span class="icon-wrap"></span></i>'],
                    autoPlay : 3000,
                    stopOnHover : true,
                    navigation:true,
                    slideSpeed: 500,
                    paginationSpeed: 400,
                    rewindSpeed: 800,
                    singleItem : true,
                    autoHeight : true,
                    transitionStyle : "fade",
                    pagination : false
                });

                $("#owl-reference").owlCarousel({
                    items : 4,
                    // Navigation
                    navigation: true,
                    navigationText: ['<i class="streamline-arrow-left-12"></i>', '<i class="streamline-arrow-right-12"></i>'],
                    //Basic Speeds
                    slideSpeed: 500,
                    paginationSpeed: 400,
                    rewindSpeed: 800,
                    //Autoplay
                    autoPlay: true,
                    stopOnHover: true,
                    // autres
                    pagination:false,
                    transitionStyle:"fade"
                });


                if (Modernizr.touch) {

                    // show the close overlay button
                    $(".close-overlay").removeClass("hidden");
										
                    // handle the adding of hover class when clicked
                    $(".img").click(function (e) {
                        if (!$(this).hasClass("hover")) {
                            $(this).addClass("hover");
                        }
                    });
										
                    // handle the closing of the overlay
                    $(".close-overlay").click(function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        if ($(this).closest(".img").hasClass("hover")) {
                            $(this).closest(".img").removeClass("hover");
                        }
                    });
                
								} else {
                
								    // handle the mouseenter functionality
                    $(".img").mouseenter(function () {
                        $(this).addClass("hover");
                    })
                
								    // handle the mouseleave functionality
										.mouseleave(function () {
											$(this).removeClass("hover");
										});

                }

                //pop up dans les articles
                $('figure').magnificPopup({
                    delegate: 'a',
                    type: 'image',
                    tLoading: 'Chargement',
                    mainClass: 'mfp-img-mobile',
                    gallery: {
                        enabled: true,
                        navigateByImgClick: true,
                        preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
                    },
                    image: {
                        tError: 'L\'image n\'a pas pu être chargée'
                    }
                });


            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS

                //Fonction pour retourner en haut de page
                $('#top').click(function (event) {
                    event.preventDefault();
                    jQuery('html, body').animate({scrollTop: 0}, 500);
                    return false;
                })
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        },
        'single_references': {
            init: function () {

                $('.popup-gallery').magnificPopup({
                    delegate: 'a.popup',
                    type: 'image',
                    tLoading: 'Chargement',
                    mainClass: 'mfp-img-mobile',
                    gallery: {
                        enabled: true,
                        navigateByImgClick: true,
                        preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
                    },
                    image: {
                        tError: 'L\'image n\'a pas pu être chargée'
                    }
                });

                $('#beforeafter').beforeAfter({
                    animateIntro : true,
                    introDelay : 2000,
                    introDuration : 500,
                    showFullLinks : true,
                    dividerColor: '#FFFFFF',
                    beforeLinkText: 'Avant',
                    afterLinkText: 'Après'
                });
            }
        },
        'post_type_archive_references': {
            init: function () {
                /*
                 * ISOTOPE
                 */

                var $container = $('#list-isotope'); //The ID for the list with all the blog posts
                $container.isotope({ //Isotope options, 'item' matches the class in the PHP
                    itemSelector: '.item',
                    layoutMode: 'masonry'
                });

                //Add the class selected to the item that is clicked, and remove from the others
                var $optionSets = $('#filters'),
                    $optionLinks = $optionSets.find('button');

                $optionLinks.click(function () {
                    var $this = $(this);
                    // don't proceed if already selected
                    if ($this.hasClass('selected')) {
                        return false;
                    }
                    var $optionSet = $this.parents('#filters');
                    $optionSets.find('.selected').removeClass('selected');
                    $this.addClass('selected');

                    //When an item is clicked, sort the items.
                    var selector = $(this).attr('data-filter');
                    $container.isotope({ filter: selector });

                    return false;
                });
            }
        },
        'page_template_template_agence': {
            init: function () {

                /*
                 * Custom Google Maps
                 */
                //set your google maps parameters
               /*   var locations = [
                    ['Ardizio', '28 avenue de Thionville <br>57140 Metz-Woippy <br>Tél : 0387324503 <br>Fax : 0387310374 <br><a href="mailto:ardizio@groupe-salmon.fr">ardizio@groupe-salmon.fr</a>', 49.1456217, 6.1623099],
                    ['Salmon Nancy', '17 rue de Frouard 54250 Champigneulles <br>Tél : 0355020180 <br>Fax : 0355020182 <br><a href="mailto:salmon@groupe-salmon.fr">salmon@groupe-salmon.fr</a>', 48.735189, 6.1658827],
                    ['Salmon Île-de-France', '7 avenue LOUISE 93360 Neuilly Plaisance <br>Tél : 0143007250 <br>Fax : 0143009337 <br><a href="mailto:salmonneuilly@groupe-salmon.fr">salmonneuilly@groupe-salmon.fr</a>', 48.8702295, 2.5153408]
                ];*/

                //google map custom marker icon - .png fallback for IE11
                var is_internetExplorer11 = navigator.userAgent.toLowerCase().indexOf('trident') > -1;
                var marker_url = ( is_internetExplorer11 ) ? '../wp-content/themes/groupesalmon/assets/images/cd-icon-location.png' : '../wp-content/themes/groupesalmon/assets/images/cd-icon-location.svg';

                //define the basic color of your map, plus a value for saturation and brightness
                var main_color = '#1e5192',
                    saturation_value = -20,
                    brightness_value = 5;


                //we define here the style of the map
                var style = [

                    {
                        //set saturation for the labels on the map
                        elementType: "labels",
                        stylers: [
                            {saturation: saturation_value}
                        ]
                    },
                    { //poi stands for point of interest - don't show these lables on the map
                        featureType: "poi",
                        elementType: "labels",
                        stylers: [
                            {visibility: "off"}
                        ]
                    },
                    {
                        //don't show highways lables on the map
                        featureType: 'road.highway',
                        elementType: 'labels',
                        stylers: [
                            {visibility: "off"}
                        ]
                    },
                    {
                        //don't show local road lables on the map
                        featureType: "road.local",
                        elementType: "labels.icon",
                        stylers: [
                            {visibility: "on"}
                        ]
                    },
                    {
                        //don't show arterial road lables on the map
                        featureType: "road.arterial",
                        elementType: "labels.icon",
                        stylers: [
                            {visibility: "off"}
                        ]
                    },
                    {
                        //don't show road lables on the map
                        featureType: "road",
                        elementType: "geometry.stroke",
                        stylers: [
                            {visibility: "off"}
                        ]
                    },
                    //style different elements on the map
                    {
                        featureType: "transit",
                        elementType: "geometry.fill",
                        stylers: [
                            {hue: main_color},
                            {visibility: "on"},
                            {lightness: brightness_value},
                            {saturation: saturation_value}
                        ]
                    },
                    {
                        featureType: "poi",
                        elementType: "geometry.fill",
                        stylers: [
                            {hue: main_color},
                            {visibility: "off"},
                            {lightness: brightness_value},
                            {saturation: saturation_value}
                        ]
                    },
                    {
                        featureType: "poi.government",
                        elementType: "geometry.fill",
                        stylers: [
                            {hue: main_color},
                            {visibility: "off"},
                            {lightness: brightness_value},
                            {saturation: saturation_value}
                        ]
                    },
                    {
                        featureType: "poi.sport_complex",
                        elementType: "geometry.fill",
                        stylers: [
                            {hue: main_color},
                            {visibility: "off"},
                            {lightness: brightness_value},
                            {saturation: saturation_value}
                        ]
                    },
                    {
                        featureType: "poi.attraction",
                        elementType: "geometry.fill",
                        stylers: [
                            {hue: main_color},
                            {visibility: "off"},
                            {lightness: brightness_value},
                            {saturation: saturation_value}
                        ]
                    },
                    {
                        featureType: "poi.business",
                        elementType: "geometry.fill",
                        stylers: [
                            {hue: main_color},
                            {visibility: "off"},
                            {lightness: brightness_value},
                            {saturation: saturation_value}
                        ]
                    },
                    {
                        featureType: "transit",
                        elementType: "geometry.fill",
                        stylers: [
                            {hue: main_color},
                            {visibility: "on"},
                            {lightness: brightness_value},
                            {saturation: saturation_value}
                        ]
                    },
                    {
                        featureType: "transit.station",
                        elementType: "geometry.fill",
                        stylers: [
                            {hue: main_color},
                            {visibility: "on"},
                            {lightness: brightness_value},
                            {saturation: saturation_value}
                        ]
                    },
                    {
                        featureType: "landscape",
                        stylers: [
                            {hue: main_color},
                            {visibility: "off"},
                            {lightness: brightness_value},
                            {saturation: saturation_value}
                        ]

                    },
                    {
                        featureType: "road",
                        elementType: "geometry.fill",
                        stylers: [
                            {hue: main_color},
                            {visibility: "on"},
                            {lightness: brightness_value},
                            {saturation: saturation_value}
                        ]
                    },
                    {
                        featureType: "road.highway",
                        elementType: "geometry.fill",
                        stylers: [
                            {hue: main_color},
                            {visibility: "on"},
                            {lightness: brightness_value},
                            {saturation: saturation_value}
                        ]
                    },
                    {
                        featureType: "water",
                        elementType: "geometry",
                        stylers: [
                            {hue: main_color},
                            {visibility: "off"},
                            {lightness: brightness_value},
                            {saturation: saturation_value}
                        ]
                    }


                ];

                //set google map options
                var map_options = {
                    center: new google.maps.LatLng(48.6252389, 4.2431229),
                    zoom: 7,
                    panControl: false,
                    zoomControl: false,
                    mapTypeControl: false,
                    streetViewControl: false,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    scrollwheel: false,
                    styles: style,
                    clickable: true
                };

                //inizialize the map
                var map = new google.maps.Map(document.getElementById('google-container'), map_options);
                var infowindow = null;

                function setMarkers(map, locations) {

                    function ClickHandler() {

                        if (infowindow) {
                            infowindow.close();
                        }

                        infowindow = new google.maps.InfoWindow();

                        infowindow.setContent('<div class="infowindowContainer"><h3 class="infowindowHeading">' + this.title + '</h3>' + this.html + '</div');

                        infowindow.open(map, this);

                    }

                    // Add the markers and infowindows to the map
                    for (var i = 0; i < locations.length; i++) {

                        var marker = new google.maps.Marker({
                            position: new google.maps.LatLng(locations[i][2], locations[i][3]),
                            map: map,
                            icon: marker_url,
                            visible: true,
                            clickable: true,
                            optimized: false,
                            title: locations[i][0],
                            html: '<div class="infowindowContent">' + locations[i][1] + '</div>'
                        });

                        google.maps.event.addListener(marker, 'click', ClickHandler);

                    }
                }

                setMarkers(map, locations);

                //add custom buttons for the zoom-in/zoom-out on the map
                function CustomZoomControl(controlDiv, map) {
                    //grap the zoom elements from the DOM and insert them in the map
                    var controlUIzoomIn = document.getElementById('cd-zoom-in'),
                        controlUIzoomOut = document.getElementById('cd-zoom-out');
                    controlDiv.appendChild(controlUIzoomIn);
                    controlDiv.appendChild(controlUIzoomOut);

                    // Setup the click event listeners and zoom-in or out according to the clicked element
                    google.maps.event.addDomListener(controlUIzoomIn, 'click', function () {
                        map.setZoom(map.getZoom() + 1);
                    });

                    google.maps.event.addDomListener(controlUIzoomOut, 'click', function () {
                        map.setZoom(map.getZoom() - 1);
                    });
                }

                var zoomControlDiv = document.createElement('div');
                var zoomControl = new CustomZoomControl(zoomControlDiv, map);

                //insert the zoom div on the top left of the map
                map.controls[google.maps.ControlPosition.LEFT_TOP].push(zoomControlDiv);
            }
        }
    };

// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

// Load Events
    $(document).ready(UTIL.loadEvents);

})
(jQuery); // Fully reference jQuery after this point.
